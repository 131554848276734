import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Full Clean 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Omar”`}</strong></p>
    <p>{`10-Thrusters (95/65)`}</p>
    <p>{`15-Bar Facing Burpees`}</p>
    <p>{`20-Thrusters`}</p>
    <p>{`25-Bar Facing Burpees`}</p>
    <p>{`30-Thrusters`}</p>
    <p>{`35-Bar Facing Burpees`}</p>
    <p>{`For time.`}</p>
    <p>{`*`}{`compare to 5/12/17`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at 9:00 & 10:00am so invite a
friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 8th.  Contact Eric at`}</em></strong>{`
`}<strong parentName="p"><em parentName="strong">{`fallscitystrength\\@gmail.com for more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      